import React, { FC } from 'react';

import Image from 'common/Image';

import { HeadBandProps } from './models';

import './HeadBand.scss';

const HeadBand: FC<HeadBandProps> = ({
  headband: {
    cta: { url, name },
    imageDesktop,
    imageMobile,
  },
}) => (
  <div className="headband text-center">
    <a href={url} aria-label={name} className="headband__image">
      <Image
        imageData={imageDesktop}
        alt={imageDesktop.name}
        className="d-none d-md-block headband__image--desk"
      />
      <Image
        imageData={imageMobile}
        alt={imageMobile.name}
        className="d-md-none headband__image--mob"
      />
    </a>
  </div>
);

export default HeadBand;
