import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { HeaderProps } from './models';

import './Header.scss';

const Header: FC<HeaderProps> = ({
  data: { logo, navLeft, navRight, socialMedia },
  settings: { siteName },
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <header className="header header__navbar">
      <Navbar expand="md" className="py-0 mx-auto align-items-md-top" expanded={isExpanded}>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-2 ms-2 border-0"
        >
          <span className="fas fa-bars" />
        </Navbar.Toggle>
        <Navbar.Brand href="/" className="mx-auto d-block d-md-none pt-3">
          <Image imageData={logo} alt={siteName} />
        </Navbar.Brand>
        <Navbar.Collapse id="menu" className="px-4 pt-4 pt-md-0 navbar__menu">
          <Nav className="navbar-nav navbar-nav-menu px-0 px-md-2 navbar__section--left">
            {navLeft.map((navItem) => (
              <Link
                to={navItem.url}
                key={navItem.name}
                className="w-100 d-block d-md-flex ps-4 ps-md-0 py-3 py-md-0 align-items-center justify-content-center text-start text-md-center nav-link section__name"
                title={navItem.name}
                data-testid="header-nav-left-link"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <span className="p-1">{navItem.name}</span>
              </Link>
            ))}
          </Nav>
          <div className="navbar-nav-logo pt-3 d-none d-md-block navbar__section--center">
            <Link to="/">
              <Image className="navbar__logo" imageData={logo} alt={siteName} />
            </Link>
          </div>
          <Nav className="navbar-nav navbar-nav-menu px-0 px-md-2 navbar__section--right">
            {navRight.map((navItem) => (
              <Link
                to={navItem.url}
                key={navItem.name}
                className="w-100 d-block d-md-flex ps-4 ps-md-0 py-3 py-md-0 align-items-center justify-content-center text-start text-md-center nav-link"
                title={navItem.name}
                data-testid="header-nav-right-link"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <span className="p-1">{navItem.name}</span>
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        <div className="d-flex mx-auto mx-md-0 navbar-social align-items-center pe-2 navbar__socials">
          <div>
            {socialMedia.map(({ url, name }) => (
              <a
                href={url}
                key={url}
                target="_blank"
                className="nav-icon mx-1"
                data-testid="header-nav-social"
                title={name}
                rel="noreferrer"
              >
                <i className={`fab fa-${name}`} />
              </a>
            ))}
          </div>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
