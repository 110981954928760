import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';

import Image from 'common/Image';
import Footer from 'components/Footer';
import HeadBand from 'components/HeadBand';
import Header from 'components/Header';
import Loadable from 'components/Loadable';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className, headband }) => {
  const { footer, header, siteSettings } = useStaticQuery(graphql`
    {
      footer {
        logo {
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 150)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 150)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 150)
            }
          }
        }
        navs {
          name
          url
        }
        contact
      }
      header {
        logo {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 200)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 70)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 70)
            }
          }
        }
        socialMedia {
          name
          url
        }
        navLeft {
          name
          url
        }
        navRight {
          name
          url
        }
        imageStore {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 135)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 135)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 135)
            }
          }
        }
        linkStore {
          name
          url
        }
      }
      siteSettings {
        siteName
      }
    }
  `);

  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  const {
    imageStore,
    linkStore: [linkStore],
  } = header;

  return (
    <div className={CSSclassNames}>
      {headband ? <HeadBand {...{ headband }} /> : null}
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      <div className="site">
        <Header data={header} settings={siteSettings} />
        <main id="main" className="layout__main">
          {children}
        </main>
        <Footer data={footer} />
        <div className="store-link">
          <Link to={linkStore.url} title={linkStore.name} rel="canonical">
            <Image imageData={imageStore} alt={linkStore.name} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Layout;
