import React, { FC } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { FooterProps } from './models';

import './Footer.scss';

const Footer: FC<FooterProps> = ({ data: { logo, navs, contact } }) => (
  <footer className="p-4 p-md-5 footer">
    <div className="container">
      <div className="row text-center text-md-start">
        <div className="col-12 col-md-4 pb-3 pb-md-0" role="img" aria-labelledby="footer-logo">
          <Image imageData={logo} alt={logo.altText} id="footer-logo" />
        </div>
        <div className="col-6 col-md-4 pt-3 pt-md-0">
          <DangerouslySetInnerHtml html={contact} element="div" />
        </div>
        <div className="col-6 col-md-4 pt-3 pt-md-0">
          <ul className="list-unstyled w-100" role="navigation">
            {navs.map(({ name, url }) => (
              <li className="item" key={name}>
                <Link to={url}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
